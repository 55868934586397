import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { width } from "tailwindcss/defaultTheme";



export default function About() {
    return (
        <>
            <IndexNavbar fixed />
            <section className=" container mx-auto header relative justify-center items-center md:pt-36 pt-32 let">
                <h3 className="md:text-4xl text-2xl text-center font-bold md:pt-4 text-green-100">
                    Delete your account
                </h3>
                
                <div className="flex flex-wrap justify center">

                <div className="w-full lg:w-2/12 md:w-4/12">
                    <img 
                        alt="..."
                        className="w-full lg:w-8/12 md:w-8/12"
                        src={require("assets/img/acc-deletion/disappointed.svg").default} />
                </div>
                <div className="w-full lg:w-8/12 md:w-12/12">
                    <h4 className="md:text-2xl text-2xl text-center font-bold md:pt-4 text-green-100"> We truly value your relationship with us, and we are sorry to see that you're considering leaving.</h4> <br/>
                    <p >                
                        We are committed to addressing any issues or concerns you may have and are open to discussing how we can better meet your needs. You can give us a call at +91 84313 18616.<br/><br/>
                        If you still wish to delete your account, please find the steps below for your reference.       
                    </p>   
                </div>
                
                </div>


                <div className="text-md md:px-28 pt-2 px-2">
                                             
                    <div class="py-5">
                                <ul type="none">
                                    <li > 
                                        <p><strong> Step 1: </strong> Click on the “Profile” button which is at the bottom right on the home screen </p> <br/>
                                        <div class="lg:w-3/12 md:w-4/12"> 
                                        <img
                                            alt="..."
                                            className="w-full"
                                            src={require("assets/img/acc-deletion/step-1.jpeg").default} />
                                            </div>
                                    </li>
                                    <br/>
                                    <li > 
                                        <p> <strong> Step 2: </strong> Click on “Delete your account” button</p>  <br/>
                                        <div class="lg:w-3/12 md:w-4/12"> 
                                        <img
                                            alt="..."
                                            className="w-full"
                                            src={require("assets/img/acc-deletion/step-2.jpeg").default} />
                                            </div>
                                        
                                    </li>
                                    <br/>
                                    <li > 
                                        <p> <strong> Step 3: </strong>  Click again on “Delete your account” button </p>  <br/>
                                        <div class="lg:w-3/12 md:w-4/12"> 
                                        <img
                                            alt="..."
                                            className="w-full"
                                            src={require("assets/img/acc-deletion/step-3.jpeg").default} />
                                            </div>
                                        
                                    </li>
                                    <br/>
                                    <li > 
                                        <p> <strong> Step 4: </strong> Enter DELETE in the given field and click on “Submit to Delete Your Account”</p>  <br/>
                                            <div class="lg:w-3/12 md:w-4/12"> 
                                            <img
                                            alt="..."
                                            className="w-full"
                                            src={require("assets/img/acc-deletion/step-4.jpeg").default} />
                                            </div>
                                        
                                    </li>
                                    <br/>
                                    <li > 
                                        <p> <strong> Step 5: </strong> Click on the “Delete” button on the popup msg. </p>  <br/>
                                        <div class="lg:w-3/12 md:w-4/12"> 
                                        <img
                                            alt="..."
                                            className="w-full"
                                            src={require("assets/img/acc-deletion/step-5.jpeg").default} />
                                            </div>
                                        
                                    </li>
                                </ul>
                            </div>    
                </div>
            </section>

            <Footer />
        </>
    );
}



